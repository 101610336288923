.navbarItems {
    background-color: rgba(90, 116, 107, .9);
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo {
    color: #232323;
    justify-self: start;
    cursor : pointer;
}


.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 5px;
    list-style: none;
    text-align: center;;
    justify-content: end;
    margin-right: 2rem;
    margin-top: 2rem;
}

.nav-links {
    color: #232323;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: #f45d48;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #232323;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}



@media screen and (max-width: 960px) {
    .navbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100px;
        position: absolute;
        top: 35px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #f8f5f2;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #f45d48;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(15%, 15%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;   
    }

    .fa-times {
        color: #232323;
        font-size: 2rem;
    }

    

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #078080;
        color: #232323;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: #232323;
        color: #fff;
        transition: 250ms;
    }

    

}
