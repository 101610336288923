.about {
  background-color: rgba(90, 116, 107, 0.9);
  color: #fff;
}

.aboutRow {
  margin: 4rem;
}

.headerRow {
  margin: 1rem;
}

p.aboutText {
  line-height: 200%;
  font-size: 140%;
}

@media screen and (max-width: 960px) {

  p.aboutText {
    line-height: 200%;
    font-size: 100%;
  }
  
}
