/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
    position: fixed;
    top: 50%;
    left:1.5%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  /* Style the icon bar links */
  .icon-bar a {
    display: block;
    transition: all 0.3s ease;
    color: white;
    background-color: #232323;
    font-size: 10px;
    border-radius: 50%;
  }
  
  /* Style the social media icons with color, if you want */
  .icon-bar a:hover {
    background-color: #f45d48;
    color: white;
    border-radius: 50%;
  }

  .fa-envelope, .fa-linkedin, .fa-github, .fa-file {
    font-size: 1.75rem;
    margin: 1rem;
}


@media screen and (max-width: 960px) {

  .icon-bar {
    display: none;
    /*position: absolute;
    top: 50%;
    left:5%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    */
  }

}

  
  

