@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
    font-family: 'Playfair Display', sans-serif;
}

.navbarItems {
    background-color: rgba(90, 116, 107, .9);
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo {
    color: #232323;
    justify-self: start;
    cursor : pointer;
}


.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 5px;
    list-style: none;
    text-align: center;;
    justify-content: end;
    margin-right: 2rem;
    margin-top: 2rem;
}

.nav-links {
    color: #232323;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: #f45d48;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #232323;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}



@media screen and (max-width: 960px) {
    .navbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100px;
        position: absolute;
        top: 35px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #f8f5f2;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #f45d48;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(15%, 15%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;   
    }

    .fa-times {
        color: #232323;
        font-size: 2rem;
    }

    

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #078080;
        color: #232323;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: #232323;
        color: #fff;
        transition: 250ms;
    }

    

}

/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
    position: fixed;
    top: 50%;
    left:1.5%;
    transform: translateY(-50%);
  }
  
  /* Style the icon bar links */
  .icon-bar a {
    display: block;
    transition: all 0.3s ease;
    color: white;
    background-color: #232323;
    font-size: 10px;
    border-radius: 50%;
  }
  
  /* Style the social media icons with color, if you want */
  .icon-bar a:hover {
    background-color: #f45d48;
    color: white;
    border-radius: 50%;
  }

  .fa-envelope, .fa-linkedin, .fa-github, .fa-file {
    font-size: 1.75rem;
    margin: 1rem;
}


@media screen and (max-width: 960px) {

  .icon-bar {
    display: none;
    /*position: absolute;
    top: 50%;
    left:5%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    */
  }

}

  
  


footer {
   background-color: rgba(90, 116, 107, .9);
    color: #fff;
}

a.footer {
   text-decoration: none;
}


 /* Style the icon bar links */
 .icon-footer a {
   transition: all 0.3s ease;
   color: white;
   font-size: 10px;
 }
 
 /* Style the social media icons with color, if you want */
 .icon-footer a:hover {
   color: #232323;
   border-radius: 50%;
 }

 .fa-envelope, .fa-linkedin, .fa-github, .fa-file {
   font-size: 1.75rem;
   margin: 1rem;
}




@media screen and (max-width: 960px) {

    

}


@media screen and (max-width: 960px) {

    
    
}
/* Styles for the Jumbotron */
.jumbotron {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.headerText {
  margin-top: 200px;
  color: #fff;
  width: 75%;
  justify-content: center;
}

.downArrow {
  color: #fff;
}

.downArrow:hover {
  padding: 8px 20px;
  transition: all 0.3s ease-out;
  color: #f45d48;
  transition: 250ms;
}

@media screen and (max-width: 900px) {
  .headerName {
    font-size: 50px;
  }
}

.about {
  background-color: rgba(90, 116, 107, 0.9);
  color: #fff;
}

.aboutRow {
  margin: 4rem;
}

.headerRow {
  margin: 1rem;
}

p.aboutText {
  line-height: 200%;
  font-size: 140%;
}

@media screen and (max-width: 960px) {

  p.aboutText {
    line-height: 200%;
    font-size: 100%;
  }
  
}

.projectRow {
  background-color: #fff;
  border-radius: 15px;
}

.img-wrap {
  position: relative;
  top: 0px;
  left: 0px;
  width: 450px;
  height: 450px;
  text-align: center;
}

.img {
  width: 100%;
  height: 75%;
  position: absolute;
  border-radius: 25px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.4);
}

.img:hover {
  opacity: 0;
  transition: opacity 1s, visibility 1s;
}

.img-description {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: inline-block;
}

/* About Project Styles */
hr {
  display: block;
  height: 1px;
  width: 75%;
  background-color: #484848;
  border: 0;
}

/* Video Style */
.video-js {
  border-radius: 10px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.4);
}

.vjs-poster {
  border-radius: 10px;
}

.icon-arrows {
  display: block;
  transition: all 0.3s ease;
  color: #f45d48;
  font-size: 10px;
}

.icon-arrows:hover {
  color: rgba(90, 116, 107, 0.9);
}

.fa-link.project {
  font-size: 2.5rem;
  padding: 10px;
  color: #232323;
}

 .fa-github.project, .fa-file.project {
  font-size: 2.5rem;
  padding: 10px;
  color: #232323;
}

.fa-github:hover, .fa-file:hover, .fa-link:hover {
  background-color: #f45d48;
  color: white;
  border-radius: 50%;
}

@media screen and (max-width: 650px) {

  .img {
    width: 100%;
    height: 95%;
    position: absolute;
    border-radius: 25px;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.4);
  }

  .icon-arrows {
    margin-bottom: 20px;
    margin-top: 20px
  }

  .linkBtn {
    margin-top: 20px;
  }

  .img-wrap {
    position: relative;
    top: 0px;
    left: 0px;
    width: 95%;
    height: 95%;
    text-align: center;
  }

}

:root {
    --primary: #f45d48;
}

.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn:hover {
    padding: 8px 20px;
    transition: all 0.3s ease-out;
    background: #fff;
    color: #232323;
    transition: 250ms;
}

.btn--primary {
    background-color: #f45d48;
    background-color: var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #232323;
    padding: 8px 20px;
    border-radius: 4px;
    border: 1px solid #f45d48;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 18px;
    color: #fff;
}

.btn--large {
    padding: 12px 26px;
    border-radius: 4px;
    font-size: 20px;
    color: #fff;
}

.headerRow {
    padding: 5%;
  }

.icons-contact {
    background-color: #f45d48;
}

.formContainer {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.4);
}

.contactForm {
    background-color: #fff;
    border-radius: 10px;
}

.info {
    background-color: rgba(90, 116, 107);
    color: #fff;
    border-radius: 15px;
}

input {
    outline: 0;
    border-width: 0 0 2px;
    border-color: #dedede
  }
  input:focus {
    border-color:rgba(90, 116, 107);
  }

.formControl {
    min-width: 100%;
    min-height: 10%;
}

.formMessage {
    min-width: 100%;
    min-height: 100px;
}


.formBtn {
    background: #f45d48;
    color: #fff;

    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
}

.formBtn:hover {
    padding: 8px 20px;
    transition: all 0.3s ease-out;
    background: rgba(90, 116, 107);
    color: #fff;
    transition: 250ms;
}


@media screen and ( max-width: 850px) {

    .contactSection {
        justify-content: center;
    }

    .phoneNumber{
        font-size: 14px;
    }

    .email{
        font-size: 14px;
    }

    .address{
        font-size: 14px;
    }

}

