footer {
   background-color: rgba(90, 116, 107, .9);
    color: #fff;
}

a.footer {
   text-decoration: none;
}


 /* Style the icon bar links */
 .icon-footer a {
   transition: all 0.3s ease;
   color: white;
   font-size: 10px;
 }
 
 /* Style the social media icons with color, if you want */
 .icon-footer a:hover {
   color: #232323;
   border-radius: 50%;
 }

 .fa-envelope, .fa-linkedin, .fa-github, .fa-file {
   font-size: 1.75rem;
   margin: 1rem;
}




@media screen and (max-width: 960px) {

    

}