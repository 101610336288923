.projectRow {
  background-color: #fff;
  border-radius: 15px;
}

.img-wrap {
  position: relative;
  top: 0px;
  left: 0px;
  width: 450px;
  height: 450px;
  text-align: center;
}

.img {
  width: 100%;
  height: 75%;
  position: absolute;
  border-radius: 25px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.4);
}

.img:hover {
  opacity: 0;
  transition: opacity 1s, visibility 1s;
}

.img-description {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: inline-block;
}

/* About Project Styles */
hr {
  display: block;
  height: 1px;
  width: 75%;
  background-color: #484848;
  border: 0;
}

/* Video Style */
.video-js {
  border-radius: 10px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.4);
}

.vjs-poster {
  border-radius: 10px;
}

.icon-arrows {
  display: block;
  transition: all 0.3s ease;
  color: #f45d48;
  font-size: 10px;
}

.icon-arrows:hover {
  color: rgba(90, 116, 107, 0.9);
}

.fa-link.project {
  font-size: 2.5rem;
  padding: 10px;
  color: #232323;
}

 .fa-github.project, .fa-file.project {
  font-size: 2.5rem;
  padding: 10px;
  color: #232323;
}

.fa-github:hover, .fa-file:hover, .fa-link:hover {
  background-color: #f45d48;
  color: white;
  border-radius: 50%;
}

@media screen and (max-width: 650px) {

  .img {
    width: 100%;
    height: 95%;
    position: absolute;
    border-radius: 25px;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.4);
  }

  .icon-arrows {
    margin-bottom: 20px;
    margin-top: 20px
  }

  .linkBtn {
    margin-top: 20px;
  }

  .img-wrap {
    position: relative;
    top: 0px;
    left: 0px;
    width: 95%;
    height: 95%;
    text-align: center;
  }

}
