/* Styles for the Jumbotron */
.jumbotron {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.headerText {
  margin-top: 200px;
  color: #fff;
  width: 75%;
  justify-content: center;
}

.downArrow {
  color: #fff;
}

.downArrow:hover {
  padding: 8px 20px;
  transition: all 0.3s ease-out;
  color: #f45d48;
  transition: 250ms;
}

@media screen and (max-width: 900px) {
  .headerName {
    font-size: 50px;
  }
}
